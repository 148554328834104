<template>
  <div id="logout">
    <v-container>
      <v-row class="pt-10">
        <v-col sm="12" align="center">
          <h1>Logging out...</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <v-row dense>
            <v-col>

            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

  export default {
    name: "Logout.vue",
    data() {
      return {

      };
    },
    created() {
      let self = this;
      this.$store.dispatch("logout");
      setTimeout(function(){
        self.$router.replace('/');
      },1000);
    },
  };
</script>

<style lang="scss" scoped>
  #logout{
    min-height: 400px;;
  }
</style>
